<template>
    <div>
        <div :id="target" @click="show = !show">
            <slot name="target" />
        </div>
        <DxPopover
            :visible="show"
            :on-hidden="close"
            :hide-on-outside-click="hideOnOutsideClick"
            :show-title="Boolean(title)"
            :width="width"
            :height="height"
            :title="title"
            :target="'#'+target"
            :position="position"
            :wrapperAttr="wrapperAttr">
            <slot name="content" />
        </DxPopover>
    </div>
</template>

<script>
import { DxPopover } from 'devextreme-vue/popover'
export default{
	name:'Popup',
	props:{
		'hide-on-outside-click': {
			type: Boolean,
			default: true
 		},
		'width': [Number, String],
		'height':{
			type: String,
			default: 'auto'
		},
		'title':{
			type: String,
			default: ''
		},
        'wrapper-attr':{
			type: Object,
			default: () => {}
		},
        'body-class':{
            type: String,
			default: ''
        },
        'no-padding':{
            type: Boolean,
            default: false
        },
        'position':{
            type: String,
			default: 'bottom'
        }
	},
	components:{ DxPopover },
	data(){
		return {
			show: false,
            target: this.generateUniqueCode()
		}
	},
    methods:{
        close(){ 
            this.show = false; 
            this.$emit('hidden')
        }
    }
}
</script>